import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordResetValidators } from './password-reset-validators';
import { AuthenticationService } from '../services/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { AlertMessage, AlertType } from '../util/alert-message';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnDestroy {
  public submitted: boolean = false;
  public showInfo: boolean = false;
  public message: string = '';
  public password: FormControl<string | null>;
  public confirmPassword: FormControl<string | null>;
  public passwordResetForm: FormGroup;

  public passwordResetClass$: BehaviorSubject<string>;
  public passwordResetMessage$: BehaviorSubject<string>;

  constructor(public authenticationService: AuthenticationService) {
    this.password = new FormControl('', [
      Validators.required,
      Validators.minLength(7),
    ]);
    this.confirmPassword = new FormControl('', [
      Validators.required,
      Validators.minLength(7),
    ]);
    this.passwordResetForm = new FormGroup(
      {
        password: this.password,
        confirmPassword: this.confirmPassword,
      },
      [PasswordResetValidators.match('password', 'confirmPassword')]
    );
    this.passwordResetClass$ = new BehaviorSubject<string>('');
    this.passwordResetMessage$ = new BehaviorSubject<string>('');
  }

  ngOnDestroy() {
    this.submitted = false;
    this.showInfo = false;
    this.message = '';
    this.password.reset();
    this.confirmPassword.reset();
    this.passwordResetForm.reset();
    this.authenticationService.showPasswordResetModal = false;
    this.passwordResetClass$.complete();
    this.passwordResetMessage$.complete();
  }

  public async resetPassword() {
    this.submitted = true;
    this.showInfo = true;
    this.passwordResetClass$.next(AlertType.INFO);
    this.passwordResetMessage$.next(AlertMessage.PASSWORD_RESET_INFO);

    this.authenticationService
      .updatePassword(this.password.value!)
      .then(() => {
        this.passwordResetClass$.next(AlertType.SUCCESS);
        this.passwordResetMessage$.next(AlertMessage.PASSWORD_RESET_SUCCESS);
      })
      .catch((reason) => {
        this.passwordResetClass$.next(AlertType.ERROR);
        this.passwordResetMessage$.next(AlertMessage.GENERIC_ERROR);
        console.error(reason);
      })
      .finally(() => {
        setTimeout(() => {
          this.submitted = false;
          this.closeModal();
        }, 100);
      });
  }

  public closeModal() {
    this.ngOnDestroy();
  }

  public getPasswordErrors(): string {
    if (this.password.errors?.['required']) {
      return 'Field is required.';
    } else if (this.password.errors?.['minlength']) {
      return 'Password must be at least 7 characters long.';
    }
    return '';
  }

  public getConfirmPasswordErrors(): string {
    if (this.confirmPassword.errors?.['required']) {
      return 'Field is required.';
    } else if (this.confirmPassword.errors?.['minlength']) {
      return 'Password must be at least 7 characters long.';
    } else if (this.confirmPassword.errors?.['noMatch']) {
      return 'Passwords do not match.';
    }
    return '';
  }
}
