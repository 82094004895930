import { Component, Input, OnInit } from '@angular/core';
import { CesiumStyleResponse } from 'src/app/models/workbench/response/cesium-style.response.model';

@Component({
  selector: 'app-style-icon',
  templateUrl: './style-icon.component.html',
})
export class StyleIconComponent implements OnInit {
  @Input() width: number = 50;
  @Input() height: number = 50;
  @Input() styleObject: CesiumStyleResponse | any;
  primaryColor: string = 'red';
  outlineWidth: number = 0;
  outlineColor: string = 'blue';
  type: string = '';
  imageSrc?: string;

  constructor() {}

  ngOnInit(): void {
    if (this.styleObject !== 'null') {
      this.type = this.styleObject.type;
      if (this.styleObject.color) {
        this.primaryColor = this.rgbaDecimalTo255Rgba(this.styleObject.color);
      }
      switch (this.type) {
        case 'solidColor':
          //...
          break;
        case 'polylineOutline':
          this.outlineColor = this.rgbaDecimalTo255Rgba(
            this.styleObject.outlineColor
          );
          this.outlineWidth = this.styleObject.outlineWidth;
          break;
        case 'polygon':
          this.outlineColor = this.rgbaDecimalTo255Rgba(
            this.styleObject.outlineColor
          );
          break;
        case 'image':
          this.imageSrc = this.styleObject.image
            ? this.styleObject.image
            : this.styleObject.imageAsBase64;
          break;
        case 'polygonImage':
          this.imageSrc = this.styleObject.image;
          break;
      }
    } else {
      this.type = 'solidColor';
      this.outlineWidth = 0;
      this.primaryColor = 'rgba(0, 0, 0, 0.1)';
      this.outlineColor = 'rgba(0, 0, 255, 0.4)';
    }
  }

  rgbaDecimalTo255Rgba(rgba: number[]) {
    let rgbaString: string = 'rgba(';
    rgba.forEach((item, index) => {
      rgbaString += item * 255 + (index === 3 ? '' : ',');
    });
    return rgbaString + ')';
  }
}
