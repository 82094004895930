<div class="card">
  <div class="card-header">Distance Measure Tool</div>
  <div class="card-body d-flex flex-column flex-fill">
    <span *ngIf="mapService.distance !== 0.00">{{mapService.distance}} m</span>
    <i>Click on the map to add another point</i>
    <button type="button" class="btn btn-sm btn-secondary rounded-pill dwm-btn"
            (click)="mapService.handleLineMeasureToolButtonClick(true)">Done
    </button>
    <button type="button" class="btn btn-sm btn-secondary rounded-pill dwm-btn-alternate"
            (click)="mapService.handleLineMeasureToolButtonClick(false)">Clear & Start Again
    </button>
  </div>
</div>
