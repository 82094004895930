export class BaseSelection {
  public index: number;
  public name: string;
  public showAccordionContent: boolean;
  public accordionId: string;
  public accordionHeadingId: string;
  public collapseTarget: string;

  constructor(index: number, name: string, showAccordionContent: boolean) {
    this.index = index;
    this.name = name;
    this.showAccordionContent = showAccordionContent;
    this.accordionId = `collapse-${name.replace(/\s/g, '')}`;
    this.accordionHeadingId = `collapse-heading-${this.accordionId}`;
    this.collapseTarget = `#${this.accordionId}`;
  }
}
