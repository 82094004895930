import { Component, Input } from '@angular/core';
import { UiService } from '../../../services/ui.service';
import { SubLayerResponse } from '../../../models/workbench/response/sub-layer-response.model';
import { LayerResponse } from '../../../models/workbench/response/layer-response.model';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent {
  @Input() layer: LayerResponse = {} as LayerResponse;
  @Input() subLayer: SubLayerResponse = {} as SubLayerResponse;
  @Input() type: string = '';
  @Input() locationIndex: number = 0;
  @Input() groupIndex: number = 0;
  @Input() layerIndex: number = 0;
  @Input() subLayerIndex: number = 0;
  @Input() imageIndex: number = 0;
  @Input() threeDObjectIndex: number = 0;
  @Input() isSubCategory: boolean = false;
  @Input() subCategoryId: number = 0;

  constructor(public uiService: UiService) {}
}
