import { BaseResponse } from './base-response.model';
import { LayerResponse } from './layer-response.model';

export class GroupResponse extends BaseResponse {
  public layers: LayerResponse[];

  static clone(groupResponse: GroupResponse[]): GroupResponse[] {
    const data: GroupResponse[] = [];

    groupResponse.forEach((gr: GroupResponse): void => {
      data.push(new GroupResponse(gr));
    });
    return data;
  }

  constructor(groupResponse: GroupResponse) {
    super(
      groupResponse.id,
      groupResponse.name,
      'group',
      groupResponse.accordionCollapsed,
      groupResponse.selected
    );
    this.layers = LayerResponse.clone(groupResponse.layers);
  }

  groupAccordionItemClicked(layerIndex: number): void {
    this.layers.forEach(
      (layer: LayerResponse, index: number): boolean =>
        (layer.accordionCollapsed = index !== layerIndex)
    );
  }
}
