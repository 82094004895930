<div class="form-sign-in w-100 m-auto">
  <form #loginForm="ngForm" (ngSubmit)="authenticate()">
    <img class="mb-4" ngSrc="assets/logo.png" alt="Gov House Logo" height="100" width="222">
    <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
    <div class="alert" [ngClass]="authenticationService.alertClass$ | async" role="alert"
         *ngIf="authenticationService.showAlert$ | async">
      {{authenticationService.alertMessage$ | async}}
    </div>
    <div class="form-floating">
      <input name="email" type="email" class="form-control" id="floatingInput" [(ngModel)]="credentials.email"
             placeholder="name@example.com">
      <label for="floatingInput">Email address</label>
    </div>
    <div class="form-floating">
      <input name="password" type="password" class="form-control" id="floatingPassword"
             [(ngModel)]="credentials.password" placeholder="Password">
      <label for="floatingPassword">Password</label>
    </div>
    <button class="w-100 btn btn-lg btn-secondary rounded-pill" type="submit"
            [disabled]="loginForm.invalid || (authenticationService.inSubmission$ | async)">
      Sign in
    </button>
  </form>
  <div class="esk-logo">
    <img class="mt-4" ngSrc="assets/eskLogo.png" alt="Esk Logo" height="75" width="125">
    <p class="version-txt">version: {{version}}</p>
  </div>
</div>
