<div style="overflow-y: auto; padding-left: 10px;">
  <div>
    <h4>{{uiService.selectedDataset!.name}}</h4>
  </div>
  <div>
    <div>
      <h5>Description</h5><span>
        <!-- TODO get specific layer information -->
      </span>
    </div>
    <div>{{uiService.selectedDataset!.name}}</div>
  </div>
</div>
