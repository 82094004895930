<div style="padding: 10px;">
  <div style="padding: 10px;" [ngClass]="{'d-flex justify-content-around': mapService.mapLayerSelection.size > 0}">
    <a class="btn btn-secondary btn-sm rounded-pill"
       (click)="uiService.showDataCatalogue = !uiService.showDataCatalogue"
       role="button">
      <i class="fa fa-plus fa-lg" aria-hidden="true"></i> Add Data
    </a>
    <a class="btn btn-secondary btn-sm rounded-pill dwm-btn-alternate" *ngIf="mapService.mapLayerSelection.size > 0"
       role="button" (click)="mapService.removeAllLayers()">
      <i class="fa fa-xmark fa-lg" aria-hidden="true"></i> Remove All Layers
    </a>
  </div>

  <ng-container *ngIf="mapService.mapLayerSelection.size > 0">
    <app-layer-selection></app-layer-selection>
  </ng-container>
</div>
