import { Component, OnDestroy } from '@angular/core';
import { MapService } from '../../services/map.service';
import { Subscription } from 'rxjs';
import { AssetPhoto } from '../../models/asset-photo.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-entity-info',
  templateUrl: './entity-info.component.html',
  styleUrls: ['./entity-info.component.scss'],
})
export class EntityInfoComponent implements OnDestroy {
  public file: any;
  public name: string = '';
  public isAssetData: boolean = false;
  public isAllTypeLayer: boolean = false;
  public assetDataWithAllTypeLayer: boolean = false;
  public propertyMap: Map<string, string> = new Map<string, string>();
  public assetPhotos: AssetPhoto[] = [];
  private assetId: number = 0;
  private nameSub: Subscription;
  private propertiesSub: Subscription;
  private isAssetDataSub: Subscription;
  private isAllDataTypeSub: Subscription;

  constructor(public mapService: MapService) {
    this.nameSub = mapService.name$.subscribe((value) => {
      this.name = value;
    });

    this.propertiesSub = mapService.properties$.subscribe((value) => {
      this.propertyMap = new Map<string, string>();
      this.assetPhotos = [];
      if (value !== null) {
        for (let key in value) {
          if (key === 'ID') {
            this.assetId = parseInt(value[key]);
          } else if (key === 'AssetPhotos' && value[key][0] !== null) {
            value[key].forEach((photo: AssetPhoto, index: number) => {
              this.assetPhotos.push({
                AddedBy: photo.AddedBy,
                AssetID: photo.AssetID,
                PhotoURL: `${environment.baseURL}/media/asset-image/${photo.PhotoURL}`,
                AddedDate: photo.AddedDate,
                ID: photo.ID,
                SlideLabel: `Slide ${index + 1}`,
              });
            });
          } else if (key === 'IsAsset') {
            this.assetDataWithAllTypeLayer = true;
          } else if (
            key !== 'StyleObject' &&
            key !== 'AssetPhotos' &&
            value[key] !== null
          ) {
            this.propertyMap.set(key, value[key]);
          }
        }
      }
    });

    this.isAssetDataSub = mapService.isAssetData$.subscribe((value) => {
      this.isAssetData = value;
    });

    this.isAllDataTypeSub = mapService.isAllTypeData$.subscribe((value) => {
      this.isAllTypeLayer = value;
    });
  }

  get isDisabled(): boolean {
    return this.file === undefined || this.mapService.uploadingFile;
  }

  ngOnDestroy() {
    this.name = '';
    this.isAssetData = false;
    this.isAllTypeLayer = false;
    this.assetDataWithAllTypeLayer = false;
    this.propertyMap = new Map<string, string>();
    this.assetPhotos = [];
  }

  uploadFile() {
    this.mapService.uploadPhotoToAssets(this.file, this.assetId);
  }

  onFileChange($event: Event) {
    this.file = ($event.target! as HTMLInputElement).files![0];
  }
}
