import { Directive, ElementRef, NgZone } from '@angular/core';
import { MapService } from '../services/map.service';
import proj4 from 'proj4';

@Directive({
  selector: '[appCesium]',
})
export class CesiumDirective {
  constructor(
    public mapService: MapService,
    private el: ElementRef,
    private ngZone: NgZone
  ) {
    ngZone.runOutsideAngular(() => {
      if (!mapService.viewer) {
        mapService.viewer = new Cesium.Viewer(this.el.nativeElement, {
          sceneMode: Cesium.SceneMode.SCENE3D,
          terrainProvider: mapService.TERRAIN_PROVIDER,
          baseLayerPicker: false,
          sceneModePicker: false,
          timeline: false,
          fullscreenButton: false,
          animation: false,
          geocoder: false,
          creditDisplay: false,
          mapProjection: new Cesium.WebMercatorProjection(),
          requestRenderMode: true,
          infoBox: false,
          msaaSamples: 2,
        });
      }
    });

    try {
      Cesium.createGooglePhotorealistic3DTileset()
        .then((tileset: any) => {
          this.mapService.viewer.scene.primitives.add(tileset);
          proj4.defs(
            'EPSG:4283',
            '+proj=longlat +ellps=GRS80 +no_defs +type=crs'
          );
          proj4.defs(
            'EPSG:7855',
            '+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'
          );

          mapService.handler = new Cesium.ScreenSpaceEventHandler(
            mapService.viewer.scene.canvas
          );

          const viewRectangle = {
            west: 147.331,
            south: -42.866,
            east: 147.3366,
            north: -42.868,
          };

          this.mapService.rectangle = Cesium.Rectangle.fromDegrees(
            viewRectangle.west,
            viewRectangle.south,
            viewRectangle.east,
            viewRectangle.north
          );

          mapService.viewer.camera.setView({
            destination: this.mapService.rectangle,
          });
          Cesium.Camera.DEFAULT_VIEW_RECTANGLE = this.mapService.rectangle;
          Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
          this.waitForLoad();
        })
        .catch(() => {
          console.log('mciomweiocjweiowemeciomewio');
        });
    }
    catch (e) {
      console.log(e)
    }
  }

  waitForLoad() {
    let mapService = this.mapService;
    let initialized = false;

    mapService.viewer.scene.globe.tileLoadProgressEvent.addEventListener(
      function () {
        if (mapService.viewer.scene.globe.tilesLoaded && !initialized) {
          // completed startup
          initialized = true;
          mapService.onLoading.next(false);
          mapService.handleMovement();
        } else {
          if (initialized) {
            if (mapService.viewer.scene.globe.tilesLoaded) {
              // Triggers when something else is loaded (after startup)
              // mapService.onLoading.next(false);
            } else {
              // Triggers when something else is loading (after startup)
              // mapService.onLoading.next(true);
            }
          } else {
            // start startup loading
            mapService.onLoading.next(true);
          }
        }
      }
    );
  }
}
