<div class="card border-dark mb-2">
  <div class="card-header">{{name}}</div>
  <div class="card-body info-card">
    <h6 class="card-title">Properties</h6>
    <table class="table table-sm table-striped">
      <tbody>
      <ng-container *ngFor="let property of propertyMap | keyvalue">
        <tr>
          <td>{{property.key}}</td>
          <td>{{property.value}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <!-- If it is either Asset Data or All Layer Type and the selected data point belongs to asset data then show the carousel -->
    <ng-container *ngIf="isAssetData || (isAllTypeLayer && assetDataWithAllTypeLayer)">
      <div id="assetPhotoCarousel" class="carousel slide" *ngIf="assetPhotos.length > 0">
        <div class="carousel-indicators">
          <ng-container *ngFor="let photo of assetPhotos; let i = index">
            <button type="button" data-bs-target="#assetPhotoCarousel" [attr.data-bs-slide-to]="i"
                    [ngClass]="{'active' : i === 0 }"
                    [attr.aria-current]="i === 0" [attr.aria-label]="photo.SlideLabel"></button>
          </ng-container>
        </div>
        <div class="carousel-inner">
          <ng-container *ngFor="let photo of assetPhotos; let i = index">
            <div class="carousel-item" [ngClass]="{'active': i === 0}">
              <a [href]="photo.PhotoURL" target="_blank">
                <img [src]="photo.PhotoURL" class="d-block w-100">
              </a>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="assetPhotos.length > 1">
          <button class="carousel-control-prev" type="button" data-bs-target="#assetPhotoCarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#assetPhotoCarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </ng-container>

      </div>
      <form (ngSubmit)="uploadFile()">
        <div class="mb-3">
          <label for="formFileSm" class="form-label">Upload Image File</label>
          <input class="form-control form-control-sm" id="formFileSm" (change)="onFileChange($event)" type="file"
                 accept="image/*">
        </div>
        <button class="col-12 btn btn-sm btn-secondary rounded-pill" [disabled]="isDisabled" type="submit">Upload
        </button>
        <div class="alert" [ngClass]="mapService.alertClass$ | async" role="alert"
             *ngIf="mapService.showAlert$ | async">
          {{mapService.alertMessage$ | async}}
        </div>
      </form>
    </ng-container>
  </div>
</div>
