import { Component, OnInit } from '@angular/core';
import { MapService } from '../../services/map.service';

@Component({
  selector: 'app-measure-tool',
  templateUrl: './measure-tool.component.html',
  styleUrls: ['./measure-tool.component.scss'],
})
export class MeasureToolComponent implements OnInit {
  constructor(public mapService: MapService) {}

  ngOnInit(): void {}
}
