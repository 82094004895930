import { IFeature } from '../geojson-response/feature.model';
import { IRequest } from '../geojson-response/request.model';
import { FeatureType } from './feature-type.model';
import { LabelType } from '../../util/label-type';
import { GeometryType } from '../../util/geometry-type';
import { CesiumStyleResponse } from '../workbench/response/cesium-style.response.model';
import { SceneType } from '../../util/scene-type';
import { SubLayerResponse } from '../workbench/response/sub-layer-response.model';

export class SubLayerSelection {
  public layerId: number;
  public name: string;
  public dataSource: any;
  public type: GeometryType;
  public labels: any;
  public layer: SubLayerResponse;
  public addedAsGroup?: boolean;
  public style?: CesiumStyleResponse;
  public features?: Map<string, FeatureType>;
  public allFeatures?: IFeature[];
  public featureData?: IRequest;
  public enableDateFilter?: boolean;
  public start?: number;
  public end?: number;
  public filteredByFeature?: boolean;
  public labelsToggled: boolean;
  public labelType: LabelType;
  public isVisible: boolean;
  private _subLayerIndex: number;
  private _addedSceneType: SceneType;
  private _isEntities: boolean;

  constructor(
    layer: SubLayerResponse,
    dataSource: any,
    style?: CesiumStyleResponse,
    scene?: any,
    addedAsGroup?: boolean,
    features?: Map<string, FeatureType>,
    allFeatures?: IFeature[],
    featureData?: IRequest,
    enableDateFilter?: boolean,
    start?: number,
    end?: number,
    filteredByFeature?: boolean
  ) {
    this.layerId = layer.id;
    this.name = layer.name;
    this.dataSource = dataSource;
    this.type = GeometryType.POINT;
    // TODO this.type = this.getGeometryType(layer.);
    this.layer = layer;
    this.style = style;
    this.labels = scene
      ? scene.primitives.add(
          new Cesium.LabelCollection({
            scene: scene,
          })
        )
      : null;
    this.addedAsGroup = addedAsGroup;
    this.features = features;
    this.allFeatures = allFeatures;
    this.featureData = featureData;
    this.enableDateFilter = enableDateFilter;
    this.start = start;
    this.end = end;
    this.filteredByFeature = filteredByFeature;
    this.labelsToggled = false;
    this.labelType = LabelType.LABEL;
    this.isVisible = true;
    this._subLayerIndex = -1;
    this._addedSceneType = SceneType.THREE_D;
    this._isEntities = false;
  }

  get isPointData(): boolean {
    return this.type === GeometryType.POINT;
  }

  get subLayerIndex(): number {
    return this._subLayerIndex;
  }

  set subLayerIndex(value: number) {
    this._subLayerIndex = value;
  }

  get addedSceneType(): SceneType {
    return this._addedSceneType;
  }

  set addedSceneType(value: SceneType) {
    this._addedSceneType = value;
  }

  get isEntities(): boolean {
    return this._isEntities;
  }

  set isEntities(value: boolean) {
    this._isEntities = value;
  }

  public changeLabelType(event: Event) {
    const target = event.target as HTMLSelectElement;
    switch (target.value) {
      case 'Label':
        this.labelType = LabelType.LABEL;
        break;

      case 'Name':
        this.labelType = LabelType.NAME;
        break;

      case 'DWM ID':
        this.labelType = LabelType.DWM_ID;
        break;

      default:
        this.labelType = LabelType.LABEL;
        break;
    }
    this.labels.removeAll();
    if (this.labelsToggled) {
      this.populateLabels();
    }
  }

  /**
   * This function is called when the label toggle is clicked.
   * If the label collection is not empty it would toggle the label's show attribute
   * If the label collection is empty it would regenerate the labels
   */
  public toggleLabels() {
    if (this.labels.length === 0) {
      this.populateLabels();
    } else {
      for (let i = 0; i < this.labels.length; i++) {
        this.labels._labels[i].show = !this.labels._labels[i].show;
      }
    }
  }

  public getStyle() {
    if (this.style) {
      return this.style;
    } else {
      return 'null';
    }
  }

  public updatePointDataClampToGround() {
    const entities = this.dataSource.entities.values;
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      entity.billboard.heightReference = Cesium.HeightReference.CLAMP_TO_GROUND;
    }
  }

  public updatePointDataRemoveHeightReference() {
    const entities = this.dataSource.entities.values;
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      entity.billboard.heightReference = Cesium.HeightReference.NONE;
    }
  }

  /**
   * This function is called to populate the label collection.
   * Based on the selected the Label Type this function would populate the label text from the entity properties
   * @private
   */
  private populateLabels() {
    const entities = this.dataSource.entities.values;
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      const labelText = this.getLabelText(this.labelType, entity);
      const { x, y, z } = entity.position._value;
      this.labels.add({
        position: new Cesium.Cartesian3.fromElements(x, y, z),
        font: 'bold 12pt sans-serif',
        text: labelText,
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.TOP,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        fillColor: Cesium.Color.WHITE,
        outlineColor: Cesium.Color.BLACK,
        outlineWidth: 20,
        pixelOffset: new Cesium.Cartesian2(15, 0),
        show: true,
      });
    }
  }

  /**
   * This function will get the correct label text from the entity based on the selected label type
   * @param labelType
   * @param entity
   * @return string|null - if the property is not null returns the value if not null
   * @private
   */
  private getLabelText(labelType: LabelType, entity: any): string | null {
    switch (labelType) {
      case LabelType.LABEL:
        return entity.properties.Label ? entity.properties.Label._value : '';

      case LabelType.NAME:
        return entity.properties.Name ? entity.properties.Name._value : '';

      case LabelType.DWM_ID:
        return entity.properties.DWM_ID ? entity.properties.DWM_ID._value : '';

      default:
        return entity.properties.Label._value;
    }
  }

  /**
   * This function gets the GeometryType based on the layer geometryTypeName
   * @param typeName
   * @return GeometryType
   * @private
   */
  private getGeometryType(typeName: string): GeometryType {
    switch (typeName) {
      case 'point':
        return GeometryType.POINT;

      case 'line':
        return GeometryType.LINE;

      case 'polygon':
        return GeometryType.POLYGON;

      default:
        return GeometryType.POINT;
    }
  }
}
