<div class="modal fade" id="updatePasswordModal" data-bs-backdrop="static" tabindex="-1"
     *ngIf="authenticationService.showPasswordResetModal"
     [ngClass]="{'show': authenticationService.showPasswordResetModal}"
     aria-labelledby="updatePasswordModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title fs-5" id="updatePasswordModalLabel">Update Password</h3>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()">
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input [formControl]="password" type="password" class="form-control form-control-sm" id="password">
            <div class="alert alert-danger" role="alert" *ngIf="password.errors">
              {{getPasswordErrors()}}
            </div>
          </div>
          <div class="mb-3">
            <label for="confirmPassword" class="form-label">Confirm Password</label>
            <input [formControl]="confirmPassword" type="password" class="form-control form-control-sm"
                   id="confirmPassword">
            <div class="alert alert-danger" role="alert" *ngIf="confirmPassword.errors">
              {{getConfirmPasswordErrors()}}
            </div>
          </div>
          <button type="submit" class="col-12 btn btn-sm btn-secondary rounded-pill"
                  [disabled]="passwordResetForm.invalid || submitted">Update Password
          </button>
          <div class="alert" [ngClass]="passwordResetClass$ | async" role="alert"
               *ngIf="showInfo">
            {{passwordResetMessage$ | async}}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
