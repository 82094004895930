import { SubLayerSelection } from './sub-layer-selection.model';
import { BaseSelection } from './base-selection.model';

export class LayerSelection extends BaseSelection {
  public layerId: number;
  public addedAsAGroup: boolean;
  public subLayersAdded: SubLayerSelection[];

  constructor(
    layerId: number,
    index: number,
    name: string,
    addedAsAGroup: boolean,
    subLayersAdded: SubLayerSelection[]
  ) {
    super(index, name, true);
    this.layerId = layerId;
    this.addedAsAGroup = addedAsAGroup;
    this.subLayersAdded = subLayersAdded;
  }
}
