import { Component } from '@angular/core';
import { MapService } from '../services/map.service';
import { UiService } from '../services/ui.service';

@Component({
  selector: 'app-workbench',
  templateUrl: './workbench.component.html',
})
export class WorkbenchComponent {

  constructor(public mapService: MapService, public uiService: UiService) {}

  msaa(number: number) {
    this.mapService.viewer.scene.msaaSamples = number;
  }
}
