import { GroupResponse } from './response/group-response.model';
import { LayerResponse } from './response/layer-response.model';

export class WorkbenchResponse {
  public data: GroupResponse[];
  public images: [];
  public threeDObjects: [];

  /**
   * Constructor for the WorkbenchResponse
   * @param response
   */
  constructor(response: WorkbenchResponse) {
    this.data = GroupResponse.clone(response.data);
    this.images = [];
    this.threeDObjects = [];
  }

  /**
   * This function will set the collapse status of the location to false and the reset of it to true
   * @param groupIndex
   */
  groupAccordionItemClicked(groupIndex: number): void {
    this.data.forEach((gr: GroupResponse, index: number): void => {
      const previous: boolean = gr.accordionCollapsed;
      gr.accordionCollapsed = index !== groupIndex;
      if (!previous) {
        gr.layers.forEach(
          (lr: LayerResponse): boolean => (lr.accordionCollapsed = true)
        );
      }
    });
  }
}
