import {
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appImagePreview]',
})
export class ImagePreviewDirective implements OnChanges {
  @Input() imageId: number = 0;
  private _viewer: any;
  private _imageryLayer: any;

  constructor(private el: ElementRef, private ngZone: NgZone) {
    ngZone.runOutsideAngular(() => {
      this._viewer = new Cesium.Viewer(el.nativeElement, {
        baseLayer: Cesium.ImageryLayer.fromProviderAsync(
          Cesium.SingleTileImageryProvider.fromUrl(
            '/assets/images/blankImage.jpg'
          )
        ),
        timeline: false,
        homeButton: false,
        animation: false,
        geocoder: false,
        fullscreenButton: false,
        baseLayerPicker: false,
        sceneModePicker: false,
        navigationHelpButton: false,
        screenSpaceEventHandler: false,
      });
      //this._viewer.cesiumWidget.creditContainer.style.display = 'none';
      this._viewer.scene.screenSpaceCameraController.enableRotate = false;
      this._viewer.scene.screenSpaceCameraController.enableTranslate = false;
      this._viewer.scene.screenSpaceCameraController.enableZoom = false;
      this._viewer.scene.screenSpaceCameraController.enableTilt = false;
      this._viewer.scene.screenSpaceCameraController.enableLook = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageId'].previousValue) {
      this._viewer.imageryLayers.remove(this._imageryLayer, true);
    }

    this._imageryLayer = Cesium.ImageryLayer.fromProviderAsync(
      Cesium.IonImageryProvider.fromAssetId(this.imageId)
    );
    this._viewer.imageryLayers.add(this._imageryLayer);
    this._viewer.zoomTo(this._imageryLayer);
  }
}
