import { Component, OnInit } from '@angular/core';
import { Credential } from '../models/authentication/credential.model';
import { AuthenticationService } from '../services/authentication.service';
import { NavigationService } from '../services/navigation.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public credentials: Credential = {} as Credential;
  public version: string = environment.appVersion;

  constructor(
    public authenticationService: AuthenticationService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    if (this.authenticationService.isAuthenticated$.value) {
      this.navigationService.navigateToMap();
    }
  }

  public authenticate() {
    //this.inSubmission = true;
    this.authenticationService.authenticateUser(this.credentials);
  }
}
