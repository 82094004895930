export class BaseResponse {
  public id: number;
  public name: string;
  public accordionCollapseId: string;
  public accordionCollapseTarget: string;
  public accordionCollapsed: boolean;
  public selected: boolean

  constructor(
    id: number,
    name: string,
    accordionId: string,
    accordionCollapsed?: boolean,
    selected?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.accordionCollapseId = `${accordionId}-${name.replace(/\s/g, '')}-${
      this.id
    }-collapse`;
    this.accordionCollapseTarget = `#${this.accordionCollapseId}`;
    this.accordionCollapsed =
      accordionCollapsed !== undefined ? accordionCollapsed : true;
    this.selected = selected ? selected : false
  }
}
