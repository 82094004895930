import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  loadingSubscription: Subscription = new Subscription();
  private isShown: boolean = true;

  constructor(private mapService: MapService) {}

  ngOnInit(): void {
    this.loadingSubscription = this.mapService.onLoading.subscribe(
      (isLoading: boolean) => {
        if (isLoading !== this.isShown) {
          this.isShown = isLoading;
          this.toggleLoadingScreen();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }

  toggleLoadingScreen() {
    let loadingBox = document.getElementById('boundingBox');
    if (this.isShown) {
      if (loadingBox) {
        loadingBox.style.display = 'flex';
      }
    } else {
      // once loaded
      if (loadingBox) {
        loadingBox.classList.add('fade-out');
      }
      setTimeout(() => {
        if (loadingBox) {
          loadingBox.style.display = 'none';
        }
      }, 500);
    }
  }
}
