import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '../models/authentication/user.model';
import { Credential } from '../models/authentication/credential.model';
import { NavigationService } from './navigation.service';
import { BehaviorSubject, map } from 'rxjs';
import { AlertMessage, AlertType } from '../util/alert-message';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public showPasswordResetModal: boolean;
  public isAuthenticated$: BehaviorSubject<boolean>;
  public inSubmission$: BehaviorSubject<boolean>;
  public alertMessage$: BehaviorSubject<string>;
  public showAlert$: BehaviorSubject<boolean>;
  public alertClass$: BehaviorSubject<string>;
  private _authenticatedUser?: User;

  constructor(
    private fireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private navigationService: NavigationService,
    private cookieService: CookieService
  ) {
    this.showPasswordResetModal = false;
    const loggedInUserId = cookieService.get('uid');
    this.isAuthenticated$ = new BehaviorSubject<boolean>(
      loggedInUserId !== ''
    );

    if (loggedInUserId !== '') {
      this.getUserDetailsByUserId(loggedInUserId);
    }

    this.inSubmission$ = new BehaviorSubject<boolean>(false);
    this.alertMessage$ = new BehaviorSubject<string>('');
    this.showAlert$ = new BehaviorSubject<boolean>(false);
    this.alertClass$ = new BehaviorSubject<string>('');
  }

  get authenticatedUser(): User {
    return this._authenticatedUser!;
  }

  public authenticateUser(credentials: Credential) {
    try {
      this.showAlert$.next(true);
      this.alertMessage$.next(AlertMessage.LOGIN_INFO);
      this.alertClass$.next(AlertType.INFO);
      this.fireAuth
        .signInWithEmailAndPassword(credentials.email, credentials.password)
        .then((userCredential) => {
          if (userCredential.user) {
            this.getUserDetailsByUserId(userCredential.user.uid);
            this.alertMessage$.next(AlertMessage.LOGIN_SUCCESS);
            this.alertClass$.next(AlertType.SUCCESS);
            this.cookieService.set('uid', userCredential.user.uid, 1, '/', undefined, true, 'Strict')
            setTimeout(() => {
              this.isAuthenticated$.next(true);
              this.navigationService.navigateToMap();
            }, 100);
          }
        })
        .catch((error: any) => {
          this.isAuthenticated$.next(false);
          this.alertClass$.next(AlertType.ERROR);
          this.inSubmission$.next(false);

          if (error.code === 'auth/user-not-found') {
            this.alertMessage$.next(AlertMessage.LOGIN_ERROR_INVALID);
          } else {
            this.alertMessage$.next(AlertMessage.GENERIC_ERROR);
          }
        });
    } catch (error: any) {
      console.log(error);
    }
  }

  public logout() {
    this.fireAuth
      .signOut()
      .then(() => {
        this.cookieService.delete('uid','/', undefined, true, 'Strict')
        this.navigationService.navigateToHome();
        this._authenticatedUser = undefined;
        this.setBehaviourSubjectsToDefault();
      })
      .catch((reason) => {
        console.error(reason);
      });
  }

  public async updatePassword(newPassword: string) {
    const user = await this.fireAuth.currentUser;
    if (user) {
      return user.updatePassword(newPassword);
    }
  }

  /**
   * This function will get the user details from the user id
   * @param uid
   * @private
   */
  private getUserDetailsByUserId(uid: string) {
    this.firestore
      .collection('Users')
      .doc<User>(uid)
      .snapshotChanges()
      .pipe(
        map((document) => {
          const data = document.payload.data();
          const id = document.payload.id;
          return { id, ...data } as User;
        })
      )
      .subscribe((value) => {
        this._authenticatedUser = value;
      });
  }

  /**
   * This function will complete all the Behaviour subjects
   * @private
   */
  private setBehaviourSubjectsToDefault() {
    this.isAuthenticated$.next(false);
    this.inSubmission$.next(false);
    this.alertMessage$.next('');
    this.showAlert$.next(false);
    this.alertClass$.next('');
  }
}
