<div>
  <div
    *ngIf="type == 'image' || type == 'polygonImage'"
    [style.height]="25 + 'px'"
    [style.width]="25 + 'px'"
  >
    <img [src]="imageSrc" [width]="25" [height]="25" />
  </div>
  <div
    *ngIf="type == 'polygon'"
    [style.height]="height + 'px'"
    [style.width]="width + 'px'"
    [style.background-color]="primaryColor"
    [style.border]="'solid ' + (height < 50 ? 1 : 4) + 'px ' + outlineColor"
  ></div>
  <span
    class="pull-center"
    *ngIf="type === 'solidColor' || type === 'polylineOutline'"
  >
    <div
      [style.height]="height / 3 + 'px'"
      [style.width]="width + 'px'"
      [style.background-color]="'transparent'"
      [style.border]="
        'solid ' +
          (outlineWidth > height / 6 ? height / 6 : outlineWidth) +
          'px ' +
          type !=
        'polylineOutline'
          ? outlineColor
          : 'transparent'
      "
    ></div>
    <div
      [style.height]="height / 3 + 'px'"
      [style.width]="width + 'px'"
      [style.background-color]="primaryColor"
      [style.border-top]="
        'solid ' +
        (outlineWidth > height / 6 ? height / 6 : outlineWidth) +
        'px ' +
        outlineColor
      "
      [style.border-bottom]="
        'solid ' +
        (outlineWidth > height / 6 ? height / 6 : outlineWidth) +
        'px ' +
        outlineColor
      "
    ></div>
    <div
      [style.height]="height / 3 + 'px'"
      [style.width]="width + 'px'"
      [style.background-color]="'transparent'"
      [style.border]="
        'solid ' +
          (outlineWidth > height / 6 ? height / 6 : outlineWidth) +
          'px ' +
          type !=
        'polylineOutline'
          ? outlineColor
          : 'transparent'
      "
    ></div>
  </span>
</div>
