<ng-container *ngFor="let selection of mapService.mapLayerSelection | keyvalue">
  <div class="accordion accordion-flush" id="mapDataAccordion">
    <div class="accordion-item">
      <h2 class="accordion-header location-heading" [id]="selection.key.accordionHeadingId">
        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                [ngClass]="{'collapsed': !selection.key.showAccordionContent}"
                (click)="selection.key.showAccordionContent = !selection.key.showAccordionContent"
                [attr.data-bs-target]="selection.key.collapseTarget" [attr.aria-controls]="selection.key.accordionId">
          {{selection.key.name}}
        </button>
      </h2>
      <div [id]="selection.key.accordionId" class="accordion-collapse collapse"
           [ngClass]="{'show': selection.key.showAccordionContent}"
           [attr.aria-labelledby]="selection.key.accordionHeadingId">
        <div class="accordion-body">
          <ng-container *ngFor="let group of selection.value; let groupIndex=index">
            <div class="accordion accordion-flush" id="'groupDataAccordion-'{{groupIndex}}">
              <div class="accordion-item">
                <h3 class="accordion-header group-heading" [id]="group.accordionHeadingId">
                  <div class="d-flex flex-row">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            [ngClass]="{'collapsed': !group.showAccordionContent}"
                            (click)="group.showAccordionContent = !group.showAccordionContent"
                            [attr.data-bs-target]="group.collapseTarget" [attr.aria-controls]="group.accordionId">
                      {{group.name}}
                    </button>
                    <button class="btn btn-sm btn-outline-secondary group-delete-btn" type="button"
                            [ngClass]="{'group-collapsed': group.showAccordionContent}"
                            (click)="mapService.removeGroupData(selection.key, group, groupIndex, false)">
                      <span class=" fa-solid fa-x"></span>
                    </button>
                  </div>
                </h3>
                <div [id]="group.accordionId" class="accordion-collapse collapse"
                     [ngClass]="{'show': selection.key.showAccordionContent}"
                     [attr.aria-labelledby]="group.accordionHeadingId">
                  <div class="accordion-body accordion-border">
                    <ng-container *ngFor="let layer of group.subLayersAdded; let layerIndex=index">`
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-1 d-flex justify-content-center flex-column">
                              <span style="padding-right: 5px">
                                <app-style-icon [width]="iconWidth" [height]="iconHeight"
                                                [styleObject]="layer.getStyle()">
                                </app-style-icon>
                                </span>
                            </div>
                            <div class="col-11">
                              <div class="card-body d-flex justify-content-between align-items-center">
                                <div>
                                  {{layer.name}}
                                  <div
                                    class="form-check form-switch d-flex justify-content-around align-items-center"
                                    *ngIf="layer.isPointData">
                                    <input class="form-check-input" type="checkbox" role="switch"
                                           [id]="'toggleLabels'+layerIndex"
                                           [(ngModel)]="layer.labelsToggled" (click)="mapService.toggleLabels(layer)">
                                    <select class="form-select form-select-sm" [ngModel]="layer.labelType"
                                            (change)="mapService.changeLayerLabelType(layer, $event)">
                                      <option value="Label">Label</option>
                                      <option value="Name">Name</option>
                                      <option value="DWM ID">DWM ID</option>
                                    </select>
                                  </div>
                                </div>
                                <ng-container
                                  *ngIf="layer.addedAsGroup; then hideBtnTemplate; else deleteBtn"></ng-container>
                                <ng-template #deleteBtn>
                                  <a type="button" class="btn btn-sm btn-outline-secondary selection-btn"
                                     (click)="mapService.removeData(selection.key, groupIndex, group, layerIndex, layer)">
                                    <span class=" fa-solid fa-x"></span>
                                  </a>
                                </ng-template>
                                <ng-template #hideBtnTemplate>
                                  <ng-container *ngIf="layer.isVisible; then showBtn; else hideBtn;"></ng-container>
                                  <ng-template #showBtn>
                                    <a type="button"
                                       class="btn btn-sm btn-outline-secondary selection-btn group-add-btn"
                                       (click)="mapService.toggleDatasourceShowState(layer)">
                                      <span class=" fa-solid fa-eye"></span>
                                      <span class=" fa-solid fa-eye-slash"></span>
                                    </a>
                                  </ng-template>
                                  <ng-template #hideBtn>
                                    <a type="button"
                                       class="btn btn-sm btn-outline-secondary selection-btn group-add-btn"
                                       (click)="mapService.toggleDatasourceShowState(layer)">
                                      <span class=" fa-solid fa-eye-slash"></span>
                                      <span class=" fa-solid fa-eye"></span>
                                    </a>
                                  </ng-template>
                                </ng-template>
                              </div>
                              <div class="form-check" *ngFor="let feature of layer.features! | keyvalue">
                                <input class="form-check-input" type="checkbox" value="" [attr.id]="feature.key">
                                <label class="form-check-label">
                                  {{feature.key}}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
