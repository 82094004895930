import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MapService } from '../services/map.service';
import { UiService } from '../services/ui.service';
import { AuthenticationService } from '../services/authentication.service';
import { Subscription } from 'rxjs';
import { EntityInfoComponent } from './entity-info/entity-info.component';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit {
  @ViewChild('entityInfo') entityInfo!: EntityInfoComponent;
  public workbenchOpened: boolean = true;
  public version: string = environment.appVersion;

  private catalogueDataSub: Subscription;

  constructor(
    public mapService: MapService,
    public uiService: UiService,
    private authenticationService: AuthenticationService
  ) {
    uiService.getWorkBenchData();
    this.catalogueDataSub = uiService.dataLoaded$.subscribe(
      (value: boolean): void => {
        if (value) {
          mapService.onLoading.next(false);
        }
      }
    );
  }

  ngAfterViewInit() {
    this.mapService.viewer.selectedEntityChanged.addEventListener(
      (selectedEntity: any) => {
        if (selectedEntity !== undefined) {
          this.mapService.name$.next(
            selectedEntity.name ? selectedEntity.name : ''
          );
          if (selectedEntity.properties) {
            this.mapService.properties$.next(
              selectedEntity.properties.getValue(Cesium.JulianDate.now)
            );
            /*this.mapService.isAssetData$.next(
              selectedEntity.description._value === LayerType.ASSET
            );*/
            this.mapService.isAllTypeData$.next(true);
            this.mapService.showInfoCard = true;
          }
        } else {
          this.mapService.name$.next('');
          this.mapService.properties$.next(null);
          this.mapService.isAssetData$.next(false);
          this.mapService.isAllTypeData$.next(false);
          this.mapService.showInfoCard = false;
          if (this.entityInfo) {
            this.entityInfo.ngOnDestroy();
          }
        }
      }
    );
  }

  public openPasswordResetModal() {
    this.authenticationService.showPasswordResetModal = true;
  }

  public logout() {
    this.authenticationService.logout();
    this.mapService.clearCesiumObjects();
  }
}
