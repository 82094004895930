import { Component } from '@angular/core';
import { MapService } from '../../services/map.service';

@Component({
  selector: 'app-layer-selection',
  templateUrl: './layer-selection.component.html',
  styleUrls: ['./layer-selection.component.scss'],
})
export class LayerSelectionComponent {
  readonly iconWidth = 32;
  readonly iconHeight = 32;

  constructor(public mapService: MapService) {}
}
