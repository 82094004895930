export enum AlertType {
  SUCCESS = 'alert-success',
  ERROR = 'alert-danger',
  INFO = 'alert-primary',
}

export enum AlertMessage{
  LOGIN_INFO = 'Please wait! We are logging you in.',
  LOGIN_SUCCESS = 'Logged in successfully.',
  LOGIN_ERROR_INVALID = 'Invalid login details.',
  GENERIC_ERROR = 'An unexpected error occurred. Please try again later.',
  FILE_UPLOAD_INFO = 'Please wait! Uploading the file.',
  FILE_UPLOAD_SUCCESS = 'File uploaded successfully.',
  PASSWORD_RESET_INFO = 'Please wait! Updating the password.',
  PASSWORD_RESET_SUCCESS = 'Password updated successfully.',
}
