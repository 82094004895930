import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// @ts-ignore
window['CESIUM_BASE_URL'] = '/assets/cesium/';

if (environment.production) {
  enableProdMode();
}
Cesium.Ion.defaultAccessToken = environment.ionKey;
Cesium.GoogleMaps.defaultApiKey = "AIzaSyBArAOC2nvRAo-mP33aGl1n_g2TTAXQx9Q";
firebase.initializeApp(environment.firebaseConfig);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
