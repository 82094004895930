import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CesiumDirective } from './map/cesium.directive';
import { DataDetailsComponent } from './catalogue/data-details/data-details.component';
import { WorkbenchComponent } from './workbench/workbench.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImagePreviewDirective } from './catalogue/data-details/image-preview.directive';
import { HttpClientModule } from '@angular/common/http';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AppRoutingModule } from './app-routing.module';
import { LayerListComponent } from './catalogue/layer-list/layer-list.component';
import { ListItemComponent } from './catalogue/layer-list/list-item/list-item.component';
import { StyleIconComponent } from './workbench/layer-selection/style-icon/style-icon.component';
import { LayerSelectionComponent } from './workbench/layer-selection/layer-selection.component';
import { LoadingScreenComponent } from './map/loading-screen/loading-screen.component';
import { EntityInfoComponent } from './map/entity-info/entity-info.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { MeasureToolComponent } from './map/measure-tool/measure-tool.component';
import { SettingsComponent } from './map/settings/settings.component';
import { AppStateService } from './services/app-state.service';
import { NgOptimizedImage } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    CesiumDirective,
    DataDetailsComponent,
    WorkbenchComponent,
    ImagePreviewDirective,
    CatalogueComponent,
    LoginComponent,
    MapComponent,
    LayerListComponent,
    ListItemComponent,
    LayerSelectionComponent,
    StyleIconComponent,
    LoadingScreenComponent,
    EntityInfoComponent,
    PasswordResetComponent,
    MeasureToolComponent,
    SettingsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgOptimizedImage,
  ],
  providers: [AppStateService, CookieService],
  bootstrap: [AppComponent],
})
export class AppModule {}
