<div class="card mb-2">
  <div class="card-header d-flex justify-content-between">
    Globe Translucency Settings
    <button type="button" class="btn-close" aria-label="Close" (click)="mapService.toggleSettings()"></button>
  </div>
  <div class="card-body">
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <label for="translucencyToggle" class="form-label">Toggle Translucency</label>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="translucencyToggle" [ngModel]="mapService.translucent"
                 (change)="toggleTranslucency($event)">
        </div>
      </div>
      <div class="row" *ngIf="mapService.translucent">
        <label for="translucencyLevel" class="form-label">Translucency Level</label>
        <div class="d-flex justify-content-between">
          <input type="range" class="form-control-sm form-range" min="0.0" max="1.0" step="0.1" id="translucencyLevel"
                 [ngModel]="mapService.range" (change)="updateRange($event)">
          <p>{{mapService.range | number: '1.1-1'}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
