import { Injectable } from '@angular/core';
import { WorkbenchResponse } from '../models/workbench/workbench.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { GroupResponse } from '../models/workbench/response/group-response.model';
import { SubLayerResponse } from '../models/workbench/response/sub-layer-response.model';
import { LayerResponse } from '../models/workbench/response/layer-response.model';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  public workbenchOpened: boolean;
  public workbenchData: WorkbenchResponse;
  public showDataCatalogue: boolean;
  public showContent: boolean;
  public selectedType?: string;
  public selectedDataset?: SubLayerResponse;
  public selectedLayer?: LayerResponse;
  public selectedGroupIndex?: number;
  public selectedLayerIndex?: number;
  public selectedSubLayerIndex?: number;
  public type: string;
  public ionImageID: number;
  public searchString: string;
  public isSubCategory: boolean;
  public subCategoryId: number;
  public workbenchData$: BehaviorSubject<WorkbenchResponse>;
  public dataLoaded$: BehaviorSubject<boolean>;
  public closeOthers$: BehaviorSubject<boolean>;
  public groupSelection: boolean;

  constructor(private httpClient: HttpClient) {
    this.workbenchOpened = false;
    this.workbenchData = {} as WorkbenchResponse;
    this.showDataCatalogue = false;
    this.showContent = false;
    this.groupSelection = false;
    this.type = '';
    this.searchString = '';
    this.isSubCategory = false;
    this.subCategoryId = 0;
    this.ionImageID = 0;

    this.workbenchData$ = new BehaviorSubject<WorkbenchResponse>(
      {} as WorkbenchResponse
    );
    this.dataLoaded$ = new BehaviorSubject<boolean>(false);
    this.closeOthers$ = new BehaviorSubject<boolean>(false);
  }

  get isSelectedLayerAlreadySelected(): boolean {
    return false;
  }

  get isSelectedGroupAlreadySelected(): boolean {
    return false;
  }

  public updateBehaviourSubjectToOriginalDataSet() {
    this.workbenchData$.next(this.workbenchData);
  }

  /**
   * This function populates the workbench/catalogue
   */
  public getWorkBenchData(): void {
    console.log(`${environment.baseURL}/populate-workbench`);
    this.httpClient
      .get<WorkbenchResponse>(`${environment.baseURL}/populate-workbench`)
      .subscribe((data: WorkbenchResponse): void => {
        this.workbenchData = new WorkbenchResponse(data);
        this.workbenchData$.next(this.workbenchData);
        this.dataLoaded$.next(true);
      });
  }

  public clearSelection(): void {
    this.clearListItemSelection();
    this.groupSelection = false;
    this.ionImageID = 0;
    this.searchString = '';
    this.closeOthers$.next(false);
    this.showDataCatalogue = false;
  }

  public clearListItemSelection(): void {
    this.showContent = false;
    this.selectedType = undefined;
    this.selectedDataset = undefined;
    this.selectedGroupIndex = undefined;
    this.selectedLayer = undefined;
    this.selectedLayerIndex = undefined;
    this.selectedSubLayerIndex = undefined;
    this.type = '';
  }

  public handleLayerAccordionClick(
    type: string,
    group: GroupResponse,
    groupIndex: number,
    layer: LayerResponse,
    layerIndex: number
  ): void {
    this.showContent = true;
    this.groupSelection = true;
    this.type = type;
    this.selectedGroupIndex = groupIndex;
    this.selectedLayer = layer;
    this.selectedLayerIndex = layerIndex;
    group.groupAccordionItemClicked(layerIndex);
  }

  public handleListItemClick(
    type: string,
    groupIndex: number,
    layer: LayerResponse,
    layerIndex: number,
    item: SubLayerResponse,
    subLayerIndex: number
  ): void {
    this.selectedType = type;
    this.selectedDataset = item;
    this.selectedGroupIndex = groupIndex;
    this.groupSelection = false;
    this.selectedLayer = layer;
    this.selectedLayerIndex = layerIndex;
    this.selectedSubLayerIndex = subLayerIndex;
  }

  public removeSelectedDataAttribute(
    groupIndex: number,
    layerIndex: number,
    subLayerIndex: number
  ): void {
    this.workbenchData.data[groupIndex].layers[
      layerIndex
    ].sub_layers!.sub_layers[subLayerIndex].selected = false;
  }

  public removeSelectedGroupAttribute(
    groupIndex: number,
    layerIndex: number
  ): void {
    this.workbenchData.data[groupIndex].layers[layerIndex].selected = false;
    this.workbenchData.data[groupIndex].layers[
      layerIndex
    ].sub_layers!.sub_layers.forEach(
      (layer: SubLayerResponse): boolean => (layer.selected = false)
    );
  }
}
