// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ionKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMzU0MmUyNC1iNzgwLTQ4ZDgtOWRkMy03M2ViMzdlYzBhZjUiLCJpZCI6OTA2NTcsImlhdCI6MTY1MDQ5OTA5Nn0.P3_zZ9IUbbGyplCKYTrGqUtflt0PMKoeaG7TUuECMGc',
  baseURL: `${location.origin}/api`,
  firebaseConfig: {
    apiKey: "AIzaSyBhV2BYYJBnBmVgWuZvRiWoyzTNFBAjP04",
    authDomain: "dwm-waste-management.firebaseapp.com",
    projectId: "dwm-waste-management",
    storageBucket: "dwm-waste-management.appspot.com",
    messagingSenderId: "748346925903",
    appId: "1:748346925903:web:960e6345013dbd9688db3a",
    measurementId: "G-K4SM9CB94V"
  },
  appVersion: require('../../package.json').version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
