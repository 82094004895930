import { AddedDataLayers } from './added-data-layers.model';
import { AddedOtherLayers } from './added-other-layers.model';

export class State {
  public data: AddedDataLayers[];
  public images: AddedOtherLayers[];
  public threeDObjects: AddedOtherLayers[];

  constructor() {
    this.data = [];
    this.images = [];
    this.threeDObjects = [];
  }

  get isEmptyObject(): boolean {
    console.log(Object.keys(this))
    return this.data.length === 0 && this.images.length === 0 && this.threeDObjects.length === 0;
  }

  get isImagesEmpty(): boolean {
    console.log(Object.keys(this))
    return this.images.length === 0;
  }
}
