import {Component, OnInit} from '@angular/core';
import {WorkbenchResponse} from '../models/workbench/workbench.model';
import {UiService} from '../services/ui.service';
import {Subscription} from 'rxjs';
import {MapService} from '../services/map.service';

@Component({
  selector: 'app-data-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss'],
})
export class CatalogueComponent implements OnInit {
  public catalogueData: WorkbenchResponse = {} as WorkbenchResponse;
  public closeOthers: boolean = false;
  public show: WorkbenchResponse = {} as WorkbenchResponse;

  private catalogueDataSub: Subscription;
  private closeOthersSub: Subscription;

  constructor(public uiService: UiService, private mapService: MapService) {
    this.catalogueDataSub = uiService.workbenchData$.subscribe(
      (value: WorkbenchResponse): void => {
        this.catalogueData = new WorkbenchResponse(value);
      }
    );

    this.closeOthersSub = uiService.closeOthers$.subscribe(
      (value: boolean): void => {
        this.closeOthers = value;
      }
    );
  }

  ngOnInit(): void {
    this.uiService.updateBehaviourSubjectToOriginalDataSet();
  }

  public handleAddToMap(close: boolean): void {
    this.mapService.addDataToMap({
      type: this.uiService.selectedType!,
      groupIndex: this.uiService.selectedGroupIndex!,
      layer: this.uiService.selectedLayer!,
      layerIndex: this.uiService.selectedLayerIndex!,
      dataset: this.uiService.selectedDataset!,
      subLayerIndex: this.uiService.selectedLayerIndex!,
    });
    if (close) {
      this.uiService.clearSelection();
    }
  }

  public handleAddToMapAsGroup(close: boolean): void {
    this.mapService.addDataToMapAsGroup({
      type: this.uiService.type,
      groupIndex: this.uiService.selectedGroupIndex!,
      layerIndex: this.uiService.selectedLayerIndex!,
      layer: this.uiService.selectedLayer!
    })
    if (close) {
      this.uiService.clearSelection();
    }
  }
}
