<div id="mapDataToggle" class="modal fade show" data-bs-backdrop="static" data-bs-keyboard="false"
     tabindex="-1" style="display: block;" aria-modal="true" role="dialog">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="dataCatalogue">Data Catalogue</h5>
        <button type="button" class="btn-close" (click)="uiService.clearSelection()"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-fill w-100">
          <nav class="nav nav-tabs flex-column" id="catalogueTab">
            <a class="nav-link nav-padding active" id="data-tab" role="tab" data-bs-toggle="tab"
               data-bs-target="#data-tab-pane" (click)="uiService.clearListItemSelection()"
               aria-controls="data-tab-pane" aria-selected="true"><i class="fas fa-database fa-2x"></i></a>
            <a class="nav-link nav-padding" id="image-tab" role="tab" data-bs-toggle="tab"
               data-bs-target="#image-tab-pane" (click)="uiService.clearListItemSelection()"
               aria-controls="image-tab-pane" aria-selected="false"><i class="fas fa-images fa-2x"></i></a>
            <a class="nav-link nav-padding" id="3d-tab" role="tab" data-bs-toggle="tab"
               data-bs-target="#3d-tab-pane" (click)="uiService.clearListItemSelection()"
               aria-controls="3d-tab-pane" aria-selected="false"><i class="fas fa-cubes fa-2x"></i></a>
          </nav>
          <div class="mt-2 tab-content flex-fill" id="catalogueTabContent">
            <div class="tab-pane fade show active" id="data-tab-pane" role="tabpanel" aria-labelledby="data-tab"
                 tabindex="0">
              <div class="col-12 col-sm-6v col-md-12 d-flex justify-content-around">
              </div>
              <div class="modal-body d-flex">
                <div class="col-4 div-bg">
                  <ng-container *ngFor="let group of catalogueData.data; let groupIndex = index">
                    <div class="accordion" id="locationDataAccordion-{{group.id}}}">
                      <div class="accordion-item">
                        <h2 class="accordion-header location-heading" [id]="group.id">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                  [ngClass]="{'collapsed': group.accordionCollapsed }"
                                  (click)="catalogueData.groupAccordionItemClicked(groupIndex)"
                                  [attr.data-bs-target]="group.accordionCollapseTarget"
                                  [attr.aria-controls]="group.accordionCollapseId">
                            {{group.name}}
                          </button>
                        </h2>
                        <div [id]="group.accordionCollapseId" class="accordion-collapse collapse"
                             [ngClass]="{'show': !group.accordionCollapsed}"
                             [attr.aria-labelledby]="group.id">
                          <div class="accordion-body">
                            <ng-container *ngFor="let layer of group.layers; let layerIndex = index">
                              <div class="accordion" id="'groupDataAccordion-'{{layer.id}}">
                                <div class="accordion-item">
                                  <h3 class="accordion-header group-heading" [id]="layer.id">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            [ngClass]="{'collapsed': layer.accordionCollapsed }"
                                            (click)="uiService.handleLayerAccordionClick(group.name, group, groupIndex, layer, layerIndex)"
                                            [attr.data-bs-target]="layer.accordionCollapseTarget"
                                            [attr.aria-controls]="layer.accordionCollapseId">
                                      {{layer.name}}
                                    </button>
                                  </h3>
                                  <div [id]="layer.accordionCollapseId" class="accordion-collapse collapse"
                                       [ngClass]="{'show': !layer.accordionCollapsed}"
                                       [attr.aria-labelledby]="layer.id">
                                    <div class="accordion-body accordion-border">
                                      <app-layer-list [type]="group.name"
                                                      [group]="group"
                                                      [groupIndex]="groupIndex"
                                                      [layer]="layer"
                                                      [subLayers]="layer.sub_layers!"
                                                      [layerIndex]="layerIndex"
                                                      [closeOthers]="closeOthers"></app-layer-list>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="col d-flex flex-column align-items-center">
                  <ng-container *ngIf="uiService.showContent; then selection; else noSelection;"></ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noSelection>
    <div class="info-txt">
      Select a dataset to know more Information and to visualise the data.
    </div>
  </ng-template>
  <ng-template #selection>
    <app-data-details></app-data-details>
    <ng-container *ngIf="uiService.groupSelection; then groupSelectionButtons; else singleSelectionButtons;"></ng-container>
    <ng-template #singleSelectionButtons>
        <button class="btn btn-secondary rounded-pill col-4 col-6 dwm-btn"
                [disabled]="uiService.isSelectedLayerAlreadySelected"
                (click)="handleAddToMap(false)">Add to Map and Stay
        </button>
        <button class="btn btn-secondary rounded-pill col-6 dwm-btn dwm-btn-alternate"
                [disabled]="uiService.isSelectedLayerAlreadySelected"
                (click)="handleAddToMap(true)">Add to Map and Close
        </button>
    </ng-template>
    <ng-template #groupSelectionButtons>
      <button class="btn btn-secondary rounded-pill col-4 col-6 dwm-btn"
              [disabled]="uiService.isSelectedGroupAlreadySelected"
              (click)="handleAddToMapAsGroup(false)">Add to Map As a Group and Stay
      </button>
      <button class="btn btn-secondary rounded-pill col-6 dwm-btn dwm-btn-alternate"
              [disabled]="uiService.isSelectedGroupAlreadySelected"
              (click)="handleAddToMapAsGroup(true)">Add to Map As a Group and Close
      </button>
    </ng-template>
  </ng-template>
</div>
