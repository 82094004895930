import { Component, Input } from '@angular/core';
import { GroupResponse } from '../../models/workbench/response/group-response.model';
import { LayerSublayerResponse } from '../../models/workbench/response/layer-sublayer-response.model';
import { LayerResponse } from '../../models/workbench/response/layer-response.model';

@Component({
  selector: 'app-layer-list',
  templateUrl: './layer-list.component.html',
})
export class LayerListComponent {
  @Input() type: string = '';
  @Input() closeOthers: boolean = true;
  @Input() group: GroupResponse = {} as GroupResponse;
  @Input() groupIndex: number = 0;
  @Input() layerIndex: number = 0;
  @Input() layer: LayerResponse = {} as LayerResponse;
  @Input() subLayers: LayerSublayerResponse = {} as LayerSublayerResponse;

  constructor() {}
}
