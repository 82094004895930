<app-loading-screen></app-loading-screen>
<div>
  <div class="workbench-btn">
    <button aria-controls="offcanvasScrolling" type="button" class="cesium-button rounded-pill"
            (click)="workbenchOpened = !workbenchOpened">
      Layers <i class="fa-solid fa-chevron-right"></i>
    </button>
  </div>
  <div class="map-btn d-flex flex-row">
    <button class="cesium-button cesium-toolbar-button" title="Settings" (click)="mapService.toggleSettings()">
      <i class="fa fa-sliders fa-lg" aria-hidden="true"></i>
    </button>
    <button class="cesium-button cesium-toolbar-button" title="Underground Toggle"
            [disabled]="mapService.undergroundViewingToggled"
            (click)="mapService.toggleUndergroundViewing()">
      <i class="fa fa-arrow-up-from-ground-water fa-flip-vertical fa-lg" aria-hidden="true"></i>
    </button>
    <button class="cesium-button cesium-toolbar-button" title="Generate Screenshot"
            (click)="mapService.generateScreenshot()">
      <i class="fa fa-camera fa-lg" aria-hidden="true"></i>
    </button>
    <button class="cesium-button cesium-toolbar-button" title="Line Measure Tool"
            [disabled]="mapService.findDistanceToggled"
            (click)="mapService.toggleLineMeasureTool()">
      <i class="fa fa-ruler-horizontal fa-lg" aria-hidden="true"></i>
    </button>
  </div>
  <div id="cesiumContainer" class="cesium-viewer-full" appCesium>
    <div class="container info-bar">
      <div class="row info-bar-text">
        <div class="col-sm">GDA 2020 Zone 55</div>
        <div class="col-sm">
          {{ mapService.east }}
        </div>
        <div class="col-sm">
          {{ mapService.north }}
        </div>
        <div class="col-sm">Elevation: {{ mapService.elevation }}</div>
      </div>
    </div>
  </div>
</div>
<div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false"
     [ngClass]="{'show': workbenchOpened, 'hiding': !workbenchOpened}"
     tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
  <div class="offcanvas-header">
    <a href="https://www.eskmapping.com.au/" target="_blank" rel="noreferrer noopener">
      <img title="Version: Development Build" height="80" ngSrc="assets/logo.png" width="190">
    </a>
    <a type="button" class="btn-close" aria-label="Close" (click)="workbenchOpened = !workbenchOpened"></a>
  </div>
  <div class="offcanvas-body">
    <app-workbench></app-workbench>
  </div>
  <div class="offcanvas-footer group-border">
    <button class="mt-3 mx-3 btn btn-outline-secondary btn-sm rounded-pill top-0 start-0 col-5"
            (click)="openPasswordResetModal()">Update Password
    </button>
    <button class="mt-3 mx-3 btn btn-outline-danger btn-sm rounded-pill bottom-0 end-0 col-5" (click)="logout()">Logout
    </button>
    <p class="mt-1 version-txt">version: {{version}}</p>
  </div>
</div>
<app-data-catalogue *ngIf="uiService.showDataCatalogue"></app-data-catalogue>
<div class="distance-measure">
  <!-- underground toggled -->
  <div class="card mb-2" *ngIf="mapService.undergroundViewingToggled">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <span style="font-size: 15px;">Underground viewing enabled</span>
        <a type="button" class="btn btn-sm btn-outline-secondary selection-btn"
           (click)="mapService.toggleUndergroundViewing()">
          <span class=" fa-solid fa-x"></span>
        </a>
      </div>
    </div>
  </div>

  <!-- Entity info card -->
  <app-entity-info #entityInfo *ngIf="mapService.showInfoCard"></app-entity-info>
  <!-- Globe transparency card -->
  <app-settings *ngIf="mapService.settingsToggled"></app-settings>
  <!-- Distance measure tool card -->
  <app-measure-tool *ngIf="mapService.findDistanceToggled"></app-measure-tool>
</div>
<app-password-reset></app-password-reset>
