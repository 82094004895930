import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router) {}

  public navigateToHome() {
    this.router.navigateByUrl('/');
  }

  public navigateToLogin() {
    this.router.navigateByUrl('/login');
  }

  public navigateToMap() {
    this.router.navigateByUrl('/map');
  }
}
