import { Component } from '@angular/core';
import { MapService } from '../../services/map.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {

  constructor(public mapService: MapService) {}

  public toggleTranslucency($event: Event) {
    const value: boolean = ($event.target as HTMLInputElement).checked;
    this.mapService.translucent = value;
    this.mapService.viewer.scene.globe.translucency.enabled = this.mapService.translucent;
    this.mapService.viewer.scene.screenSpaceCameraController.enableCollisionDetection =
      !this.mapService.translucent;
    if (value) {
      this.mapService.viewer.scene.globe.translucency.frontFaceAlphaByDistance =
        new Cesium.NearFarScalar(400.0, 0.0, 800.0, 1.0);
    } else {
      this.mapService.viewer.scene.globe.translucency.frontFaceAlphaByDistance.nearValue = 1.0;
    }
    this.mapService.viewer.scene.requestRender();
  }

  public updateRange($event: Event) {
    const value = ($event.target as HTMLInputElement).value;
    if (value) {
      this.mapService.range = parseFloat(value);
      this.mapService.viewer.scene.globe.translucency.frontFaceAlphaByDistance.nearValue =
        this.mapService.range;
    }
  }
}
