import { BaseResponse } from './base-response.model';
import { LayerSublayerResponse } from './layer-sublayer-response.model';

export class LayerResponse extends BaseResponse {
  public group_id: number;
  public style_id?: number;
  public add_as_group?: boolean;
  public sub_layers?: LayerSublayerResponse;

  static clone(layerResponse: LayerResponse[]): LayerResponse[] {
    const data: LayerResponse[] = [];
    layerResponse.forEach((layer: LayerResponse) => {
      data.push(new LayerResponse(layer));
    });
    return data;
  }

  constructor(layer: LayerResponse) {
    super(
      layer.id,
      layer.name,
      'layer',
      layer.accordionCollapsed,
      layer.selected
    );
    this.group_id = layer.group_id;
    this.style_id = layer.style_id;
    this.add_as_group = layer.add_as_group;
    this.sub_layers = layer.sub_layers;
  }
}
