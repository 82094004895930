import { Injectable } from '@angular/core';
import { State } from '../models/state/state.model';
import { AddedDataLayers } from '../models/state/added-data-layers.model';
import { DataType } from '../util/data-type';
import { AddedOtherLayers } from '../models/state/added-other-layers.model';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  public state: State;
  private readonly SESSION_KEY: string = 'app-state';

  constructor() {
    this.state = new State();
  }

  /**
   * When loading the Map view this function will be called to check if there's a state
   */
  public getDataFromLocalstorage(): State | null {
    const appState = sessionStorage.getItem(this.SESSION_KEY);
    if (appState) {
      this.state = JSON.parse(appState) as State;
      return this.state;
    } else {
      return null;
    }
  }

  /**
   * This function save layer data into the state and the persist into the session storage.
   * @param layer
   */
  public saveDataLayerToState(layer: AddedDataLayers): void {
    this.getDataFromLocalstorage();
    this.state.data.push(layer);
    this.saveOrUpdateSessionStorage();
  }

  /**
   * This function save other data (imagery & 3D objects) into the state and the persist into the session storage.
   * @param layer
   * @param type
   */
  public saveOtherLayerToState(layer: AddedOtherLayers, type: DataType): void {
    this.getDataFromLocalstorage();
    switch (type) {
      case DataType.IMAGE:
        this.state.images.push(layer);
        this.saveOrUpdateSessionStorage();
        break;

      case DataType.THREE_D_OBJECTS:
        this.state.threeDObjects.push(layer);
        this.saveOrUpdateSessionStorage();
        break;
    }
  }

  /**
   * This function will be called when a layer is removed from the map view.
   * First will find the layer from the state using the passed layerId, then remove the layer from the app state
   * @param layerId
   */
  public removeDataLayerFromState(layerId: number): void {
    this.getDataFromLocalstorage();
    const layer = this.state.data.find((d) => d.layerId === layerId);
    if (layer) {
      const index = this.state.data.indexOf(layer);
      this.state.data.splice(index, 1);
      this.saveOrUpdateSessionStorage();
    }
  }

  /**
   * This function will be called when a layer group is removed from the map view.
   * First will find the layers belonging to the group from the state using the passed groupId.
   * Then traverse through the list and remove each layer from the app state.
   * @param groupId
   */
  public removeDataGroupFromState(groupId: number): void {
    this.getDataFromLocalstorage()
    const groups = this.state.data.filter((d) => d.groupId === groupId);
    if (groups.length > 0) {
      this.state.data = this.state.data.filter((d) => !groups.includes(d));
      this.saveOrUpdateSessionStorage();
    }
  }

  /**
   * This function will be called when Imagery or ThereDObjects are removed from the view.
   * @param id
   * @param type
   */
  public removeOtherLayerFromState(id: number, type: DataType): void {
    this.getDataFromLocalstorage();
    switch (type) {
      case DataType.IMAGE:
        const image = this.state.images.find((l) => l.id === id);
        const imgIndex = this.state.images.indexOf(image!);
        this.state.images.splice(imgIndex, 1);
        this.saveOrUpdateSessionStorage();
        break;

      case DataType.THREE_D_OBJECTS:
        const threeDObj = this.state.threeDObjects.find((l) => l.id === id);
        const threeDObjIndex = this.state.images.indexOf(threeDObj!);
        this.state.threeDObjects.splice(threeDObjIndex, 1);
        this.saveOrUpdateSessionStorage();
        break;
    }
  }

  /**
   * This function will save or update the session storage by converting the existing state object into a JSON.
   * @private
   */
  private saveOrUpdateSessionStorage(): void {
    sessionStorage.setItem(this.SESSION_KEY, JSON.stringify(this.state));
  }
}
